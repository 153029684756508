import logo from './lead.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={logo} width="400" height="400" alt="Hire me!"/>
      <p>We split the revenue!</p>
      <a href="artem.tghcd@gmail.com">drop me a line</a>
    </div>
  );
}

export default App;
